import { InteractionConfig } from './types';
import { AnimatePresence, motion } from 'framer-motion';
import { useState, useEffect, useRef } from 'react';

export default function Interaction(props: {
	shouldPlay: boolean;
	config: InteractionConfig;
	onLoaded: () => void;
	onDone: () => void;
	onError: (e: Error) => void;
}) {

	const { config, onLoaded, onDone, shouldPlay } = props;

	const [loaded, setLoaded] = useState(false); // pre loading state
	const [mirrored, setMirrored] = useState(false); // pre loading state
	const soundRef = useRef<HTMLAudioElement>(null);
	const narrationRef = useRef<HTMLAudioElement>(null);
	const [adjustedImageHeight, setAdjustedImageHeight] = useState(0);
	const [adjustedImageWidth, setAdjustedImageWidth] = useState(0);

	// const [imageAspectRatio, setImageAspectRatio] = useState(0);
	const imageRef = useRef(null);

	useEffect(() => {
		if (imageRef.current) {
			const img = new Image();
			img.src = config.picSrc;
			img.onload = () => {
				// setImageHeight(img.height);
				//calculate aspect ratio
				// Aspect ratio (AR) = Original Width / Original Height 
				// let mirrored = config?.mirrored ?? false;
				const aspectRatio = img.width / img.height;

				if (aspectRatio >= 1) { // AR >= 1
					setAdjustedImageWidth(0)
					let calc = (img.height * 400) / img.width
					let verticalOffset = (400 - calc) / 2; // Offset for vertically centering

					setAdjustedImageHeight(calc + verticalOffset)
					window.parent.postMessage({ status: 'ok', data: ` new image height ${calc}` }, '*');
				} else { // AR < 1
					setAdjustedImageHeight(400);
					let calc = (img.width * 400) / img.height
					//horizontal offset far right
					let horizontalOffest = (400 - calc) / 2; // Offset for vertically centering
					setAdjustedImageWidth(horizontalOffest)


				}
				window.parent.postMessage({ status: 'ok', error: `image height ${adjustedImageHeight}` }, '*');
				setLoaded(true);
			};
			img.onerror = () => window.parent.postMessage({ status: 'error', error: 'failed to load image' }, '*');
		} else {
			window.parent.postMessage({ status: 'error', error: `failed to load image ${imageRef.current}` }, '*');
		}
	}, [config, adjustedImageHeight, adjustedImageWidth]);



	useEffect(() => {
		if (!loaded) return;
		onLoaded();
		setTimeout(() => {
			onDone();
		}, config.timeout || 20_000);
	}, [loaded, config, onDone, onLoaded]);

	useEffect(() => {
		if (!shouldPlay) return;
		setMirrored(config?.mirrored ?? false);
		soundRef.current!.volume = config?.volume ?? 0.7;
		soundRef.current!.play();
		soundRef.current?.addEventListener("ended", () => {
			setTimeout(() => {
				if (!narrationRef.current) {
					return
				} else {
					narrationRef.current.volume = Math.min(soundRef.current!.volume * 1.3, 1);
				}
				narrationRef.current.play()
			}, 500)
		})
	}, [shouldPlay, config]);

	// mirrored check if config?.volume is true or false if true we want to flip the image


	// const Cube = styled(motion.div)`
	// 	position: relative;
	// 	width: 800px;
	// 	height: 800px;
	// 	transform-style: preserve-3d;
	// 	`;

	// const Container = styled.div`
	// 	display: flex;
	// 	justify-content: center;
	// 	align-items: center;
	// 	height: 100vh;
	// 	perspective: 5000px;
	//   `;
	// const CubeFace = styled(motion.div)`
	// 	position: absolute;
	// 	width: 800px;
	// 	height: 800px;
	// 	display: flex;
	// 	justify-content: center;
	// 	align-items: center;
	// 	font-size: 24px;
	// 	color: white;
	// `;
	// const Front = styled(CubeFace)`
	//   transform: rotateY(0deg) translateZ(400px);
	// `;

	// const Back = styled(CubeFace)`
	//   transform: rotateY(180deg) translateZ(400px);
	// `;

	// const Left = styled(CubeFace)`
	//   transform: rotateY(-90deg) translateZ(400px);
	// `;

	// const Right = styled(CubeFace)`
	//   transform: rotateY(90deg) translateZ(400px);
	// `;

	// const Top = styled(CubeFace)`
	//   transform: rotateX(90deg) translateZ(400px);
	// `;

	// const Bottom = styled(CubeFace)`
	//   transform: rotateX(-90deg) translateZ(350px);
	// `;
	return (
		<>

			<AnimatePresence>
				{config?.TTSNarrationURL ? (
					<audio src="/drumroll.mp3" ref={soundRef} />
				) : (
					<audio src="/laughtrack.mp3" ref={soundRef} />
				)
			
			}


				
				{/* container */}
				<motion.div style={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					height: "100vh",
					perspective: "500px"
				}}>
					{/* cube */}
					<motion.div
						animate={{
							rotateY: [90, 0],
						}}
						transition={{
							duration: 5,
							ease: "easeInOut",
							loop: 1
						}}
						style={{
							position: "relative",
							width: "400px",
							height: "400px",
							transformStyle: "preserve-3d"
						}}
					>
						{/* FRONT CUBE FACE */}
						<motion.div
							style={{
								position: "absolute",
								width: "400px",
								height: "400px",
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								fontSize: "24px",
								transform: "rotateY(0deg) translateZ(200px)",
								// backgroundColor: "RED"

							}}
						>
							<div
								style={{
									display: "flex",
									flexDirection: "column",
									position: "absolute",
									width: "100%",
									height: "100%",
									justifyContent: "center",
									alignItems: "center",
									placeItems: "center",

								}}

							>
								<div
									ref={imageRef}
									style={{
										width: "100%",
										height: "100%",
										backgroundImage: `url('${config.picSrc}')`,
										backgroundRepeat: "no-repeat",
										backgroundSize: "contain",
										backgroundPosition: "center",
										transform: mirrored ? "scaleX(-1)" : "none",
									}}
								/>
								{loaded && (
									<div
										className='flex flex-row items-center justify-center rounded-br-md rounded-bl-md rounded-tl-md bg-[#252424] text-white px-3 py-[6px] border-b-[0.5px] border-l-[0.5px] border-r-[0.5px] border-white'
										style={{
											position: 'absolute',
											top: `${adjustedImageHeight}px`,
											right: `${adjustedImageWidth}px`, // Adjust dynamically based on image width
											whiteSpace: 'nowrap',


										}}
									>
										{config?.TTSNarrationURL && <audio src={config.TTSNarrationURL} autoPlay={false} style={{ display: 'none' }} ref={narrationRef} />}
										<div className='flex items-center space-x-2'>
											<img className='h-4 w-auto' src='logo.svg' alt='error' />
											<div className='text-xs font-extrabold'>
												{config.msgFrom}
											</div>
										</div>
									</div>
								)}
							</div>
						</motion.div>
						{/* cube */}
					</motion.div>
					{/* container */}
				</motion.div>
				{/* </div> */}
			</AnimatePresence>
		</>
	);
}

